import * as React from "react"
import * as styles from "./css/repaymentVia.module.styl"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { widthInfo } from "../util/index.js"
import Topbtn from "../components/topButton/topButton"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
let width = widthInfo()
const easypaisaPage = () => {
  const PictureData = useStaticQuery(graphql`
    query easypaisaQuery {
      bannerPicture: file(relativePath: { eq: "paymentVia/bannerbg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      activeLinePicture: file(
        relativePath: { eq: "paymentVia/activeLine.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS1: file(relativePath: { eq: "paybyEasypaisa/UBPS1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS2: file(relativePath: { eq: "paybyEasypaisa/UBPS2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS3: file(relativePath: { eq: "paybyEasypaisa/UBPS3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS4: file(relativePath: { eq: "paybyEasypaisa/UBPS4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS5: file(relativePath: { eq: "paybyEasypaisa/UBPS5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS6: file(relativePath: { eq: "paybyEasypaisa/UBPS6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS7: file(relativePath: { eq: "paybyEasypaisa/UBPS7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS8: file(relativePath: { eq: "paybyEasypaisa/UBPS8.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UBPS9: file(relativePath: { eq: "paybyEasypaisa/UBPS9.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD1: file(relativePath: { eq: "paybyEasypaisa/USSD1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD2: file(relativePath: { eq: "paybyEasypaisa/USSD2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD3: file(relativePath: { eq: "paybyEasypaisa/USSD3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD411: file(relativePath: { eq: "paybyEasypaisa/USSD411.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD412: file(relativePath: { eq: "paybyEasypaisa/USSD412.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD413: file(relativePath: { eq: "paybyEasypaisa/USSD413.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD421: file(relativePath: { eq: "paybyEasypaisa/USSD421.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD422: file(relativePath: { eq: "paybyEasypaisa/USSD422.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD423: file(relativePath: { eq: "paybyEasypaisa/USSD423.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD424: file(relativePath: { eq: "paybyEasypaisa/USSD424.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD425: file(relativePath: { eq: "paybyEasypaisa/USSD425.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD5: file(relativePath: { eq: "paybyEasypaisa/USSD5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD6: file(relativePath: { eq: "paybyEasypaisa/USSD6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD7: file(relativePath: { eq: "paybyEasypaisa/USSD7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD8: file(relativePath: { eq: "paybyEasypaisa/USSD8.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      USSD9: file(relativePath: { eq: "paybyEasypaisa/USSD9.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const {
    bannerPicture,
    activeLinePicture,
    UBPS6,
    UBPS7,
    UBPS8,
    UBPS1,
    UBPS2,
    UBPS3,
    UBPS4,
    UBPS5,
    UBPS9,
    USSD1,
    USSD2,
    USSD3,
    USSD411,
    USSD412,
    USSD413,
    USSD421,
    USSD422,
    USSD423,
    USSD424,
    USSD425,
    USSD5,
    USSD6,
    USSD7,
    USSD8,
    USSD9,
  } = PictureData
  let gotop = () => {
    document.documentElement.scrollTop = 0
  }
  let scrollTop = 0
  return (
    <Layout>
      <Seo title="BarwaqtRepaymentMethods"></Seo>
      <header className={styles.paybanner}>
        <div className={styles.paybannercenter}>
          <div className={styles.paybannerInfo}>
            {width > 1024 ? (
              <div className={styles.paybannerTitle}>
                Barwaqt
                <br />
                Repayment Methods
              </div>
            ) : null}
            <Img
              className={styles.paybannerLogo}
              fluid={bannerPicture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
        </div>
      </header>
      {width > 1024 ? (
        // pc
        <div className={styles.paynav}>
          <div className={styles.payNav80}>
            <li className={styles.payli}>
              <div className={styles.payliInfo}>
                <span>
                  {" "}
                  <Link to="/oneBill"> Repayment Via 1Bill </Link>
                </span>
              </div>
            </li>
            <li className={styles.payli}>
              <div className={styles.payliInfo}>
                <span>
                  {" "}
                  <Link to="/repaymentViabyWa">
                    Repayment Via Mobile Wallet
                  </Link>
                </span>
              </div>
            </li>
            <li className={styles.payli}>
              <div className={styles.payliInfo}>
                <span className={styles.active}>
                  {" "}
                  Repayment Via Easypaisa
                  <Img
                    className={styles.paybottomLine}
                    fluid={activeLinePicture.childImageSharp.fluid}
                    alt="A corgi smiling happily"
                  />
                </span>
              </div>
            </li>
          </div>
        </div>
      ) : (
        // H5
        <div className={`${styles.paynav} `}>
          <div className={styles.payNav80}>
            <div className={`${styles.payli}  ${styles.payliInfo}`}>
              <span>
                {" "}
                <Link to="/oneBill">1Bill </Link>
              </span>
            </div>
            <div className={`${styles.payli} ${styles.payliInfo}`}>
              <span>
                {" "}
                <Link to="/repaymentViabyWa"> MOBILE WALLET </Link>
              </span>
            </div>
            <div
              className={`${styles.payli} ${styles.payliInfo} ${styles.payliRightLine}`}
            >
              <span> EASYPAISA </span>
            </div>
          </div>
        </div>
      )}

      <div className={styles.content}>
        {/* 中间内容 */}
        <div className={styles.bigTitle}>
          {" "}
          <span className={styles.bigTitleGreen}>01</span> Easypaisa UBPS
        </div>
        {/* 01 */}
        <div className={styles.marginLeft54}>
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}01
            </span>
            <span className={styles.contentTitleText}>
              Go to the Repayment Methods on your Barwaqt App and choose
              “Repayment Via Easypaisa”.
            </span>
          </div>
          <Img
            className={styles.pict525}
            fluid={UBPS1.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}02
            </span>
            <span className={styles.contentTitleText}>
              Your “Loan Id Number” is a unique Id which will be required in
              order to pay your loan amount. You may copy your Loan Id number by
              clicking the “Copy” button.
            </span>
          </div>
          <Img
            className={styles.pict481}
            fluid={UBPS2.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}03
            </span>
            <span className={styles.contentTitleText}>
              Go to the Easypaisa App and tap the search icon on the top right
              corner of your App.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={UBPS3.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}04
            </span>
            <span className={styles.contentTitleText}>
              Type “Barwaqt” in the search bar and select it.
            </span>
          </div>
          <Img
            className={styles.pict481}
            fluid={UBPS4.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}05
            </span>
            <span className={styles.contentTitleText}>
              On the Payments page, enter your “Barwaqt Loan Id Number” in the
              Voucher ID.
            </span>
          </div>
          <Img
            className={styles.pict481}
            fluid={UBPS5.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}06
            </span>
            <span className={styles.contentTitleText}>
              Bill details against your Loan Id will be shown. Tap the “PAY NOW”
              button to proceed.
            </span>
          </div>
          <Img
            className={styles.pict481}
            fluid={UBPS6.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}07
            </span>
            <span className={styles.contentTitleText}>
              Tap the “PAY NOW” button to make the loan re-payment successful.
            </span>
          </div>
          <Img
            className={styles.pict481}
            fluid={UBPS7.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}08
            </span>
            <span className={styles.contentTitleText}>
              Congratulations! Your loan amount has been paid. Transaction
              successful receipt will be received from the Easypaisa.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={UBPS8.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}09
            </span>
            <span className={styles.contentTitleText}>
              Finally, you can see your order has been settled on the Barwaqt
              App.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={UBPS9.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
        </div>
        <div className={styles.bigTitle}>
          {" "}
          <span className={styles.bigTitleGreen}>02</span> Easypaisa USSD
        </div>
        {/* 02 */}
        <div className={styles.marginLeft54}>
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}01
            </span>
            <span className={styles.contentTitleText}>
              Dial *786# on the dialer of your Mobile phone.
            </span>
          </div>
          <Img
            className={styles.pict525}
            fluid={USSD1.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}02
            </span>
            <span className={styles.contentTitleText}>
              The Easypaisa menu will be shown on the screen. Type “4” in the
              input field and tap the “Send” button to enter the “Payments”
              menu.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={USSD2.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}03
            </span>
            <span className={styles.contentTitleText}>
              The Payments menu will be shown on the screen. Type “6” in the
              input field and tap the “Send” button to enter the “Loan
              Repayments” menu.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={USSD3.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={`${styles.contentTitle} `}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}04
            </span>
            <span className={styles.contentTitleText}>
              There are two methods to find “Barwaqt”.{" "}
            </span>
          </div>
          <div className={styles.marginTop24}></div>
          <div className={styles.foursamllPart}>
            <div className={`${styles.secondTitle} `}>
              <span className={styles.secondTitleIcon}>01</span>
              <span className={styles.secondTitleText}>Method 1 </span>
            </div>
            <div className={`${styles.fourthredPart} `}>
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>
                  Type “98” in the input field and tap the Send button to enter
                  the “Search” option.
                </span>
              </div>
              <Img
                className={styles.pict312}
                fluid={USSD411.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>
                  Type “Barwaqt” or its initials in the input field and tap the
                  Send button.
                </span>
              </div>
              <Img
                className={styles.pict312}
                fluid={USSD412.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>
                  “Barwaqt” will appear in the search result. Now type “1” in
                  the input field and tap the Send button to select Barwaqt.
                </span>
              </div>
              <Img
                className={styles.pict312}
                fluid={USSD413.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={`${styles.secondTitle} `}>
                <span className={styles.secondTitleIcon}>02</span>
                <span className={styles.secondTitleText}>Method 2 </span>
              </div>
              <div className={`${styles.fourthredPart} `}>
                <div className={styles.fourItemTitle}>
                  <span className={styles.fourItemIcon}></span>
                  <span className={styles.fourItemText}>
                    Type “99” in the input field and tap the Send button to see
                    the list of the lenders. Repeat this action, until the
                    “Barwaqt” can be seen in the options.
                  </span>
                </div>
                <Img
                  className={styles.pict312}
                  fluid={USSD421.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <Img
                  className={styles.pict312}
                  fluid={USSD422.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <Img
                  className={styles.pict312}
                  fluid={USSD423.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <Img
                  className={styles.pict312}
                  fluid={USSD424.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <div className={styles.fourItemTitle}>
                  <span className={styles.fourItemIcon}></span>
                  <span className={styles.fourItemText}>
                    Type “5” in the input field and tap the Send button to
                    choose “Barwaqt” from the list.
                  </span>
                </div>
                <Img
                  className={styles.pict312}
                  fluid={USSD425.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
              </div>
            </div>
          </div>
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}05
            </span>
            <span className={styles.contentTitleText}>
              Type your “Barwaqt Loan Id Number” in the input field and tap the
              Send button to proceed.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={USSD5.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}06
            </span>
            <span className={styles.contentTitleText}>
              Type Barwaqt, Barwaqt Loan, or any other message in “Any
              Reference” field and tap the Send button.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={USSD6.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}07
            </span>
            <span className={styles.contentTitleText}>
              Enter your “5-digit Easypaisa PIN” and tap the Send button.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={USSD7.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}08
            </span>
            <span className={styles.contentTitleText}>
              Congratulations! Your loan amount has been paid. You will also
              receive a confirmation SMS on your mobile phone.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={USSD8.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>
              {" "}
              {width > 1024 ? <span>STEP </span> : null}09
            </span>
            <span className={styles.contentTitleText}>
              Finally, you can see your order has been settled on the Barwaqt
              App.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={UBPS9.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
        </div>
      </div>
      {width > 1024 ? <Topbtn></Topbtn> : null}
    </Layout>
  )
}

export default easypaisaPage
