import * as React from "react"
import * as styles from '../../pages/css/topButton.module.styl'
class Topbtn extends React.Component {
  constructor(props) {

    super(props);
    this.state = this.initialState;

  }
  get initialState() {
    return {
      scrollTop:378,
      hasVerticalScrolled: false
    }
  }
  //在componentDidMount，进行scroll事件的注册，绑定一个函数，让这个函数进行监听处理   
  componentDidMount() { 
    window.addEventListener('scroll', this.bindHandleScroll)
 }
  //在componentWillUnmount，进行scroll事件的注销   
  componentWillUnmount() { window.removeEventListener('scroll', this.bindHandleScroll); }
  bindHandleScroll = (event) => {
    // 滚动的高度       
    const top = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
    this.setState({
      hasVerticalScrolled: top > 378
    })
  }
  gotop = () => {
    document.documentElement.scrollTop = this.state.scrollTop
  }
  render() {
    return (
      <div className={`${this.state.hasVerticalScrolled ? 'block' : 'none'} ${styles.topTipsContent}` }> 
        <div className={styles.topTips}>Return to top</div>
        <div onClick={() => this.gotop()} className={`${this.state.hasVerticalScrolled ? 'block' : 'none'} ${styles.upButton}`} >
        </div> 
      </div>)
  }

}






export default Topbtn